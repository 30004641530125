/* @import url(' https://fonts.google.com/specimen/Press+Start+2P?preview.text_type=custom&query=press+start+2p#type-tester'); */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

* {
    font-family: 'Press Start 2P', cursive;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    background-color: black;
    color: #98ff98;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.social-link {
    color: #fff;
}
.social-link:hover {
    color: #98ff98;
}
